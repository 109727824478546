let core = orbit.npm.find(e => e.package == "@startinblox/core");
import(`https://cdn.skypack.dev/@startinblox/core@${core.version}`).then(core => {
  core.Sib.register({
    name: 'orbit-reactivity',
    use: [core.StoreMixin],
    attributes: {
      targetSrc: {
        type: String,
        default: '',
        callback: function () {
          this.subscribe();
        }
      }
    },
    async fetchData(value) {
      this.resourceId = null;
      if (this.nestedField) {
        const resource = core.store.get(value) || await core.store.getData(value, this.context);
        const nestedResource = await resource[this.nestedField]
        this.resourceId = nestedResource ? nestedResource['@id'] : null;
      } else {
        this.resourceId = value;
      }
      await this.replaceAttributesData();
      this.subscribe();
    },
    unsubscribe(resourceId, targetSrc) {
      const resourcesSub = core.store.subscriptionVirtualContainersIndex.get(resourceId);
      const targetSub = core.store.subscriptionVirtualContainersIndex.get(targetSrc);
      const newResourceSub = resourcesSub.filter(r => r !== targetSrc);
      const newTargetSub = targetSub.filter(r => r !== resourceId);
      core.store.subscriptionVirtualContainersIndex.set(resourceId, newResourceSub);
      core.store.subscriptionVirtualContainersIndex.set(targetSrc, newTargetSub);
    },
    detached() {
      this.unsubscribe(this.resourceId, this.targetSrc)
    },
    subscribe() {
      if (this.oldResourceId && this.oldTargetSrc && (this.oldResourceId !== this.resourceId || this.oldTargetSrc !== this.targetSrc)) {
        this.unsubscribe(this.oldResourceId, this.oldTargetSrc);
      }
      if (this.resourceId && this.targetSrc) {
        core.store.subscribeVirtualContainerTo(this.resourceId, this.targetSrc);
        core.store.subscribeVirtualContainerTo(this.targetSrc, this.resourceId);
        this.oldResourceId = this.resourceId;
        this.oldTargetSrc = this.targetSrc;
      }
    }
  });
});