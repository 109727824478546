document.addEventListener("DOMContentLoaded", () => {
  const MenuTabs = Array.from(document.querySelectorAll(".jsMenuTab"));
  MenuTabs.forEach(menuTab => {
    const menuHeader = menuTab.querySelector('.jsMenuHeader');
    const menuArrow = menuTab.querySelector('.jsMenuArrow')
    const menuSub = menuTab.querySelector('.jsMenuSub');
    const menuOption = menuTab.querySelector('.jsMenuOption');
    const menuAdmin = menuTab.querySelector('.jsMenuAdmin');

    const isParent = (refNode, otherNode) => {
      var parent = otherNode.parentNode;
      do {
        if (refNode == parent) {
          return true;
        } else {
          parent = parent.parentNode;
        }
      } while (parent);
      return false;
    }
    if (menuAdmin) {
      menuAdmin.addEventListener("mouseover", function (ev) {
        if (!isParent(this, ev.relatedTarget) && ev.target == this) {
          menuAdmin.classList.toggle('hidden', false);
        }
      }, false);
      menuAdmin.addEventListener("mouseout", function (ev) {
        if (!isParent(this, ev.relatedTarget) && ev.target == this) {
          menuAdmin.classList.toggle('hidden', true);
        }
      }, false);
      menuAdmin.querySelector('solid-link').addEventListener('click', e => {
        menuAdmin.classList.toggle('hidden', true);
      });
    }

    if (menuHeader) {
      menuHeader.addEventListener('click', e => {
        menuArrow.classList.toggle('transform-up');
        menuSub.classList.toggle('hidden');
      });
    }

    if (menuOption) {
      menuOption.addEventListener('click', e => {
        menuAdmin.classList.toggle('hidden');
        e.stopPropagation();
      });
    }
  });

  const searchForm = document.querySelector('.menu-search');
  searchForm.addEventListener('formChange', () => {
    if (document.querySelector('.menu-search input').value != "") {
      searchForm.style.backgroundColor = "#202B3C";
      document.querySelector('#main__menu').classList.toggle('searching', true);
    } else {
      searchForm.style.backgroundColor = "var(--color-heading)";
      document.querySelector('#main__menu').classList.toggle('searching', false);
    }
  })
});