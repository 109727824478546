// auxiliary function closes the user profile menu
function closeUserControls() {
  let userControls = Array.from(document.querySelectorAll(".user-controls"));
  userControls.forEach(e => e.removeAttribute("open"));
}

function closeLeftMenu() {
  let leftMenu = document.querySelector("#main__menu");
  if (leftMenu) leftMenu.removeAttribute("open");
}

function closeRightMenu() {
  let rightMenu = document.querySelectorAll(".jsRightMenu");
  if (Array.from(rightMenu).filter(el => el.hasAttribute("open")).length > 0) {
    Array.from(document.querySelectorAll(".views-container")).forEach(vC =>
      vC.classList.toggle("sidebar-is-closed")
    );
    Array.from(document.querySelectorAll(".jsOffsiteToggle")).forEach(el => {
      el.querySelector('.text-right').setAttribute('hidden', '');
      el.querySelector('.text-left').removeAttribute('hidden');
    });
    Array.from(rightMenu).forEach(el => {
      el.removeAttribute("open");
      el.classList.add('sm-hidden');
      Array.from(el.parentElement.querySelectorAll('.jsMobileRightMenuButton')).forEach(ac => {
        ac.classList.remove('icon-close');
        ac.classList.add('icon-options-vertical');
      });
    });
  }
}

function openRightMenu() {
  let rightMenu = document.querySelectorAll(".jsRightMenu");
  Array.from(rightMenu).forEach(el => {
    el.setAttribute("open", "");
    el.classList.remove('sm-hidden');
    Array.from(el.parentElement.querySelectorAll('.jsMobileRightMenuButton')).forEach(ac => {
      ac.classList.remove('icon-options-vertical');
      ac.classList.add('icon-close');
    });
  });
  Array.from(document.querySelectorAll(".jsOffsiteToggle")).forEach(el => {
    el.querySelector('.text-left').setAttribute('hidden', '');
    el.querySelector('.text-right').removeAttribute('hidden');
  });
  Array.from(document.querySelectorAll(".views-container")).forEach(vC =>
    vC.classList.toggle("sidebar-is-closed")
  );
}

function closeRightMobileMenu() {
  let rightMenu = document.querySelectorAll(".jsRightMenu");
  if (Array.from(rightMenu).filter(el => el.hasAttribute("mobile-open")).length > 0) {
    Array.from(rightMenu).forEach(el => {
      el.removeAttribute("mobile-open");
      el.classList.add('sm-hidden');
      Array.from(el.parentElement.querySelectorAll('.jsMobileRightMenuButton')).forEach(ac => {
        ac.classList.remove('icon-close');
        ac.classList.add('icon-options-vertical');
      });
    });
  }
}

function openRightMobileMenu() {
  let rightMenu = document.querySelectorAll(".jsRightMenu");
  Array.from(rightMenu).forEach(el => {
    el.setAttribute("mobile-open", "");
    el.classList.remove('sm-hidden');
    Array.from(el.parentElement.querySelectorAll('.jsMobileRightMenuButton')).forEach(ac => {
      ac.classList.remove('icon-options-vertical');
      ac.classList.add('icon-close');
    });
  });
}

document.addEventListener("DOMContentLoaded", function () {
  const componentSet = new Set(window.orbit.components.map(c => c.type));

  // Workaround - No "navigate" event after the login on `/login`
  if (window.location.pathname == "/login") {
    const sibAuth = document.querySelector("sib-auth");
    const timeoutNoUser = setTimeout(() => {
      if(!(new URLSearchParams(window.location.search)).get('code'))
        sibAuth.login();
    }, 15000);
    if (sibAuth) {
      sibAuth.getUser()
        .then(user => {
          clearTimeout(timeoutNoUser);
          if (user !== null) {
            window.dispatchEvent(
              new CustomEvent('requestNavigation', {
                detail: {
                  route: window.orbit.getRoute((window.orbit.defaultRoute || "dashboard"), true)
                }
              }),
            );
          }
        });
    }
  }

  //- View change event
  window.addEventListener("navigate", e => {
    if (componentSet.has('admin')) {
      const onlyAdmin = document.querySelector('.only-on-admin');
      if (onlyAdmin) {
        if (e.detail.route.startsWith('admin-')) {
          onlyAdmin.removeAttribute('hidden');
        } else {
          onlyAdmin.setAttribute('hidden', '');
        }
      }
      for (component of window.orbit.components) {
        let adminTarget = document.querySelector(`solid-route[name="admin-${component.route}"]`);
        let adminTargetTwo = document.querySelector(`.jsRightMenu solid-link[next="admin-${component.route}"]`);
        if (e.detail.route.startsWith(`admin-${component.route}`)) {
          if (adminTarget) adminTarget.setAttribute('active', '');
          if (adminTargetTwo) adminTargetTwo.setAttribute('active', '');
        } else {
          if (adminTarget) adminTarget.removeAttribute('active');
          if (adminTargetTwo) adminTargetTwo.removeAttribute('active');
        }
      }
    }
    closeLeftMenu();
    closeUserControls();
    if (e.detail.route.startsWith('login')) {
      const sibAuth = document.querySelector("sib-auth");
      const timeoutNoUser2 = setTimeout(() => {
        if((new URLSearchParams(window.location.search)).get('code'))
          sibAuth.login();
      }, 15000);
      if (sibAuth) {
        sibAuth.getUser()
          .then(user => {
            clearTimeout(timeoutNoUser2);
            if (user !== null) {
              window.dispatchEvent(
                new CustomEvent('requestNavigation', {
                  detail: {
                    route: window.orbit.getRoute((window.orbit.defaultRoute || "dashboard"), true)
                  }
                }),
              );
            }
          });
      }
    }
  });
  // Document -> close menu
  document.addEventListener("click", event => {
    if (!event.target.closest(".user-controls")) {
      closeUserControls();
    }
    if (
      !event.target.closest("#main__menu") &&
      event.target.id != "toggleMainMenu"
    ) {
      closeLeftMenu();
    }
    if (
      !event.target.closest(".jsOffsiteToggle") &&
      !event.target.classList.contains('jsMobileRightMenuButton')
    ) {
      closeRightMenu();
      closeRightMobileMenu();
    }
  });

  // listen for keypress
  document.onkeydown = e => {
    e = e || window.event;
    if (e.key === "Escape" || e.key === "Esc") {
      closeUserControls();
      closeLeftMenu();
      closeRightMenu();
      closeRightMobileMenu();
    }
  };

  const toggleMainMenu = document.querySelector("#toggleMainMenu")
  if (toggleMainMenu) {
    toggleMainMenu.addEventListener("click", () => {
      let leftMenu = document.querySelector("#main__menu");
      if (leftMenu.hasAttribute("open")) {
        closeLeftMenu();
      } else {
        leftMenu.setAttribute("open", "");
      }
    });
  }

  const rightMenus = Array.from(document.querySelectorAll("nav.jsRightMenu"));
  rightMenus.forEach(rightMenu => {
    const btnRightMenu = rightMenu.querySelector("li.jsOffsiteToggle");
    btnRightMenu.addEventListener("click", () => {
      if (rightMenu.hasAttribute("open")) {
        closeRightMenu();
      } else {
        openRightMenu();
      }
    });
  });


  Array.from(document.querySelectorAll(".jsMobileRightMenuButton")).forEach(
    el => {
      el.addEventListener("click", () => {
        if (el.closest('.jsMobileContentSidebarControl').querySelector('nav.jsRightMenu').hasAttribute("mobile-open")) {
          closeRightMobileMenu();
        } else {
          openRightMobileMenu();
        }
      });
    }
  );
});