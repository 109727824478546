setTimeout(() => {
  const loggedinLoader = document.querySelector('.loggedIn-loader');
  if (loggedinLoader) {
    if (loggedinLoader.style.display != 'none') {
      const somethingGoesWrong = document.querySelector('#something-goes-wrong');
      if (somethingGoesWrong) {
        somethingGoesWrong.removeAttribute('hidden');
      }
    }
  }
}, 15000);