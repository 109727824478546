document.addEventListener("DOMContentLoaded", () => {
  if (typeof Sentry !== 'undefined') {
    Sentry.init({
      dsn: 'https://b4b29557689049a39168599577adb940@sentry.startinblox.com/4',
      integrations: [new Sentry.Integrations.BrowserTracing()],
      environment: document.location.hostname,
      tracesSampleRate: 0.2,
    });
    window.orbit.sentry = Sentry;
  }
});