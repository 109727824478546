import(`https://storage.googleapis.com/workbox-cdn/releases/6.1.5/workbox-window.prod.mjs`).then(workboxWindow => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {

      const wb = new workboxWindow.Workbox('/service-worker.js');
      let registration;

      const showSkipWaitingPrompt = (event) => {
        if (orbit.intl.t('serviceWorker.newUpdate') != undefined) {
          Swal.fire({
            position: 'bottom-end',
            backdrop: false,
            title: "",
            text: orbit.intl.t('serviceWorker.newUpdate') + ". " + orbit.intl.t('serviceWorker.wantToUpdate'),
            imageUrl: orbit.client.logo || 'https://cdn.startinblox.com/logos/webp/startinblox.webp',
            imageAlt: orbit.client.name,
            showCancelButton: true,
            confirmButtonClass: 'button text-xsmall text-bold text-center reversed color-secondary bordered icon icon-check icon-margin-right-xsmall no-background-image',
            cancelButtonClass: 'button text-xsmall text-bold text-center reversed color-primary bordered icon icon-exclamation icon-margin-right-xsmall no-background-image',
            confirmButtonText: orbit.intl.t('serviceWorker.yes'),
            cancelButtonText: orbit.intl.t('serviceWorker.no')
          }).then((result) => {
            if (result.isConfirmed) {
              wb.addEventListener('controlling', (event) => {
                window.location.reload();
              });

              if (registration && registration.waiting) {
                workboxWindow.messageSW(registration.waiting, {
                  type: 'SKIP_WAITING'
                });
              }
            }
          });
        }
      };

      wb.addEventListener('waiting', showSkipWaitingPrompt);
      wb.addEventListener('externalwaiting', showSkipWaitingPrompt);

      wb.register().then((r) => registration = r);
    });
  }
});