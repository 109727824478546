/*
  Geocoord helper using Nominatim
  Usage:
  const madrid = await orbit.geocoord('Madrid');
  madrid == ["-3.7035825", "40.4167047"]
*/
window.orbit.geocoord = async (address = false) => {
  if (address) {
    const nominatim = await fetch('https://nominatim.openstreetmap.org/?format=geocodejson&limit=1&q=' + encodeURI(address));
    const response = await nominatim.json();
    if (response.features[0]) {
      const coords = response.features[0].geometry.coordinates;
      if (coords[0] && coords[1]) {
        return [String(coords[0]), String(coords[1])];
      }
    } else {
      console.error("Address not found");
    }
  } else {
    console.error("Missing address");
  }
  return ["-47.15", "-123.716667"];
}

window.orbit.geocalc = (element) => {
  const editionForm = element.parentElement.parentElement.parentElement.parentElement;
  window.orbit.geocoord(editionForm.querySelector('input[name="address_line1"]').value + " " + editionForm.querySelector('input[name="address_line2"]').value).then(coords => {
    editionForm.querySelector('input[name="lat"]').value = coords[1];
    editionForm.querySelector('input[name="lng"]').value = coords[0];
    editionForm.querySelector('input[type="submit"]').click();
  });
  return false;
}