document.addEventListener('DOMContentLoaded', () => {
  const userCreationForm = document.querySelector('#user-creation-form');
  if (userCreationForm) {
    userCreationForm.addEventListener('save', event => {
      if (event.originalTarget.id == 'user-creation-form') {
        if(!(new URLSearchParams(window.location.search)).get('code'))
          document.querySelector("sib-auth").login();
      }
    });
  }
});