window.requestLogin = false;
document.addEventListener("DOMContentLoaded", function () {
  const sibAuth = document.querySelector("sib-auth");
  const timeoutNoUser = setTimeout(() => {
    if(!(new URLSearchParams(window.location.search)).get('code'))
      sibAuth.login();
  }, 15000);
  if (sibAuth) {
    sibAuth.getUser()
      .then(user => {
        clearTimeout(timeoutNoUser);
        if (user !== null) {
          document
            .querySelectorAll(".notLoggedIn")
            .forEach(el => (el.style.visibility = "visible"));
          document
            .querySelectorAll(".loggedIn")
            .forEach(el => (el.style.display = "none"));
          document
            .querySelectorAll(".loggedIn-loader")
            .forEach(el => (el.style.display = "none"));
        } else if (sibAuth.hasAttribute("allow-anonymous")) {
          document
            .querySelectorAll(".notLoggedIn")
            .forEach(el => (el.style.visibility = "visible"));
          document
            .querySelectorAll(".loggedIn-loader")
            .forEach(el => (el.style.display = "none"));
        } else {
          window.requestLogin = true;
          window.dispatchEvent(
            new CustomEvent('requestNavigation', {
              detail: {
                route: "login",
                wanted: true
              }
            }),
          );
          document
            .querySelectorAll(".loggedIn-loader")
            .forEach(el => (el.style.display = "none"));
        }
      });
  }
});
window.addEventListener("navigate", e => {
  if (e.detail.route == "login" && !window.requestLogin && window.orbit.getRoute) {
    window.dispatchEvent(
      new CustomEvent('requestNavigation', {
        detail: {
          route: window.orbit.getRoute((window.orbit.defaultRoute || "dashboard"), true)
        }
      }),
    );
  }
});

const goToLoginHandler = () => {
  document
    .querySelectorAll(".loggedIn")
    .forEach(el => (el.style.display = "none"));
  document
    .querySelectorAll(".loggedIn-loader")
    .forEach(el => (el.style.display = "flex"));
  setTimeout(() => {
    document.querySelector('#something-goes-wrong').removeAttribute('hidden');
  }, 5000);
  if (!(new URLSearchParams(window.location.search)).get('code'))
    document.querySelector('sib-auth').login();
};
const loginButton = document.querySelector('#loginButton');
if (loginButton) {
  loginButton.addEventListener('click', goToLoginHandler);
}
const loginButtonHeader = document.querySelector('#loginButtonHeader');
if (loginButtonHeader) {
  loginButtonHeader.addEventListener('click', goToLoginHandler);
}