document.addEventListener("DOMContentLoaded", () => {
    if (!document.querySelector('.input-color')) return
    const params = new URLSearchParams(window.location.search);
    const currentPrimary = getComputedStyle(document.documentElement).getPropertyValue('--color-primary')
    const defaultPrimary = params.has('p') ? "#" + params.get('p') : currentPrimary ? currentPrimary.trim() : "#FF0055";
    document.documentElement.style.setProperty('--color-primary', defaultPrimary);
    const cP = Pickr.create({
        el: '.input-color#color-primary',
        theme: 'nano',
        default: defaultPrimary,
        defaultRepresentation: 'HEX',
        swatches: null,
        components: {
            hue: true,
            preview: true,
            palette: true,
            interaction: {
                input: true
            }
        }
    });
    cP.on('change', (color, evt) => {
        document.documentElement.style.setProperty('--color-primary', color.toHEXA());
        params.set('p', String(color.toHEXA()).substr(1));
        cP.applyColor();
    });
    const currentSecondary = getComputedStyle(document.documentElement).getPropertyValue('--color-secondary')
    const defaultSecondary = params.has('s') ? "#" + params.get('s') : currentSecondary ? currentSecondary.trim() : "#0068FF";
    document.documentElement.style.setProperty('--color-secondary', defaultSecondary);
    const cS = Pickr.create({
        el: '.input-color#color-secondary',
        theme: 'nano',
        default: defaultSecondary,
        defaultRepresentation: 'HEX',
        swatches: null,
        components: {
            hue: true,
            preview: true,
            palette: true,
            interaction: {
                input: true
            }
        }
    });
    cS.on('change', (color, evt) => {
        document.documentElement.style.setProperty('--color-secondary', color.toHEXA());
        params.set('s', String(color.toHEXA()).substr(1));
        cS.applyColor();
    });
    const currentThird = getComputedStyle(document.documentElement).getPropertyValue('--color-third')
    const defaultThird = params.has('c') ? "#" + params.get('c') : currentThird ? currentThird.trim() : "#00E3B4";
    document.documentElement.style.setProperty('--color-third', defaultThird);
    const cC = Pickr.create({
        el: '.input-color#color-third',
        theme: 'nano',
        default: defaultThird,
        defaultRepresentation: 'HEX',
        swatches: null,
        components: {
            hue: true,
            preview: true,
            palette: true,
            interaction: {
                input: true
            }
        }
    });
    cC.on('change', (color, evt) => {
        document.documentElement.style.setProperty('--color-third', color.toHEXA());
        params.set('c', String(color.toHEXA()).substr(1));
        cC.applyColor();
    });
    const currentHeading = getComputedStyle(document.documentElement).getPropertyValue('--color-heading')
    const defaultHeading = params.has('cd') ? "#" + params.get('cd') : currentHeading ? currentHeading.trim() : "#2E3F58";
    document.documentElement.style.setProperty('--color-heading', defaultHeading);
    const cCd = Pickr.create({
        el: '.input-color#color-heading',
        theme: 'nano',
        default: defaultHeading,
        defaultRepresentation: 'HEX',
        swatches: null,
        components: {
            hue: true,
            preview: true,
            palette: true,
            interaction: {
                input: true
            }
        }
    });
    cCd.on('change', (color, evt) => {
        document.documentElement.style.setProperty('--color-heading', color.toHEXA());
        params.set('cd', String(color.toHEXA()).substr(1));
        cCd.applyColor();
    });
});