let core = orbit.npm.find(e => e.package == "@startinblox/core");
import(`https://cdn.skypack.dev/@startinblox/core@${core.version}`).then(core => {
  core.Sib.register({
    name: 'orbit-auto-login',
    created() {
      if (window.location.pathname == "/login") {
        document
          .querySelectorAll(".loggedIn-loader")
          .forEach(el => (el.style.display = "flex"));
        window.dispatchEvent(
          new CustomEvent('requestNavigation', {
            detail: {
              route: window.orbit.getRoute((window.orbit.defaultRoute || "dashboard"), true)
            }
          }),
        );
        if(!(new URLSearchParams(window.location.search)).get('code'))
          document.querySelector("sib-auth").login();
      }
    }
  });
})