if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', async (event) => {
    if (event.data.meta === 'workbox-broadcast-update') {
      const {
        cacheName,
        updatedURL
      } = event.data.payload;

      const cache = await caches.open(cacheName);
      const updatedResponse = await cache.match(updatedURL);
      try {
        const updatedData = await updatedResponse.json();
        if (sibStore && "setLocalData" in sibStore) {
          sibStore.setLocalData(updatedData, updatedURL);
        }
      } catch (e) {
        console.error(e);
      }
    }
  });
}